import JoinUs from "../components/JoinUs/JoinUs";
import Footer from "../components/Footer/Footer";
import Platform from "../components/Platform/Platform";
import './index.scss'
import ReactFullpage from '@fullpage/react-fullpage';
import FiveBox from "../components/FiveBox/FiveBox";
import { useEffect, useRef } from "react";
import video from '../../static/videos.mp4'
function Page1({ nextPage }) {
  const handleDowmload = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  return (
    <div className="pages-1 h-screen page-style">
      <p className="p1">星耀数字AI重塑世界合作方式</p>
      <p className="p2">为全球构造100万数字人口</p>
      <p className="p3">......</p>
      {/* <div className="btn" onClick={() => nextPage()}>立即了解</div> */}
      <div className="btn-box">
        <div className="btn" onClick={() => handleDowmload('https://apps.apple.com/cn/app/uuinin/id6737213354')}>
          <img className="btn-icon1" src="https://obs-uuinin.obs.ap-southeast-3.myhuaweicloud.com:443/20250207/1738922411.png" alt="" />App Store
        </div>
        <div className="btn" onClick={() => handleDowmload('https://uuinin.obs.ap-southeast-1.myhuaweicloud.com/20241204/app-release.apk')}>
          <img className="btn-icon2" src="https://obs-uuinin.obs.ap-southeast-3.myhuaweicloud.com:443/20250207/1738922362.png" alt="" />Android
        </div>
      </div>
    </div>
  )
}
function Page3() {
  const videoDom = useRef(null)
  const windowWidth = window.innerWidth
  useEffect(() => {
    window.addEventListener('touchstart', function () {
      if (videoDom.current) {
        videoDom.current.play()
      }
    })
    return window.removeEventListener('touchstart', function () { })
  }, [])
  return (
    <div className="pages-3 h-screen page-style">
      <p className="p1">提供多种AIGC能力，用AI给予创作另一种可能</p>
      <p className="p2">商业落地成熟应用</p>
      <div className="img-box">
        {/* <video
          x5-video-player-fullscreen="true"
          x5-playsinline='true'
          playsInline={true}
          webkit-playsinline='true'
          data-wf-ignore={true}
          data-object-fit="cover"
          poster='http://image.wuyougoodcar.com/renhuo/2023-07-08/PS85AtWSXzeV7sSWAvOrowL8IYnsnX3dSNjypp1Z.png'
          muted={true} className="video-style"
          autoPlay={true}
          loop={true}
          width={windowWidth > 996 ? 438 : 220}
          src="http://image.wuyougoodcar.com/renhuo/2023-07-12/pD33yvQW5yADWPNcZob50BzCESYpzqfVKdFgy8sH.mp4"
        ></video> */}
        <video
          ref={videoDom}
          playsInline
          webkit-playsinline='true'
          muted={true}
          className="video-style"
          autoPlay={true}
          loop={true}
          width={windowWidth > 996 ? 438 : 220}
        >
          <source src={video} type="video/mp4" ></source>
          <p>您的浏览器不支持video</p>
        </video>
        {/* <img src="http://image.wuyougoodcar.com/renhuo/2023-07-08/PS85AtWSXzeV7sSWAvOrowL8IYnsnX3dSNjypp1Z.png"></img> */}
      </div>
      <p className="p3">为各大企业提供专属数字人定制服务，无需真人出镜，减少线下人力物力成本。定制化的数字人口型与配音字幕同步，符合多种场景需求。</p>
    </div>
  )
}
function Page4() {
  return (
    <div className="pages-4 h-screen page-style">
      <p className="p1">星耀AI</p>
      <p className="p2">商业落地成熟应用</p>
      <div className="page-4-list">
        <div className="list-item item1 h-full">数字IP</div>
        <div className="list-item item2 h-full">视频生成</div>

        <div className="list-item-center h-full">数字人定制</div>

        <div className="list-item item3 h-full">对话交互</div>
        <div className="list-item item4 h-full">智能语音</div>
      </div>
      <p className="p3">AI数字化应用，重新定义传统场景</p>
    </div>
  )
}
function Page5() {
  return (
    <div className="page-5 h-screen page-style">
      <p className="p1">知名投资方</p>
      <p className="p2"> 成立以来，获得包括腾讯、招银电信、红杉资本、招银国际、奇虎360等国内外知名投资机构的多轮融资。</p>
      <div className="content w-full">
        <img className="big" src="http://image.wuyougoodcar.com/renhuo/2023-06-29/GqwVVgFt5SSniEyc1KEWr5rj2LTKRCJMrcca5bsj.png"></img>
        <img className="item" src="http://image.wuyougoodcar.com/renhuo/2023-07-02/E3awl6fPam1d0NkgyF2wU8JL6wOYaeOZP3GPVYo1.png"></img>
        <img className="item" src="http://image.wuyougoodcar.com/renhuo/2023-07-02/yXtfGPwftsifrRjr3BhlK86Yp4XH5ZuRsOjY8OJD.png"></img>
        <img className="item" src="http://image.wuyougoodcar.com/renhuo/2023-07-02/pz10An6f56K6olNW0qKk8XfryzrL05xQbLKwYB4A.png"></img>
        <img className="item" src="http://image.wuyougoodcar.com/renhuo/2023-07-02/PEJ63GkrQGO2sZYcB8lMjlW0KdYbrRo7TvmMA7WS.png"></img>
        <img className="item" src="http://image.wuyougoodcar.com/renhuo/2023-07-02/4jJRatgn4FIcThSOpTigndxWuWykg89g0u9OXyLS.png"></img>
        <img className="item" src="http://image.wuyougoodcar.com/renhuo/2023-07-02/2oJjxFdpgv8C73W3Oafsi6ztyTmPE5Pql5qKXv8M.png"></img>
        <img className="item" src="http://image.wuyougoodcar.com/renhuo/2023-07-02/2N9UBvyEfkDtt1FBEKnnrGpeaxdi07a3uFfbpwUq.png"></img>
      </div>
    </div>
  )
}
function Page6() {
  return (
    <div className="page-6 h-screen page-style">
      <p className="p1">赋能企业</p>
      <p className="p2">成功服务于40+行业30000+知名企业/机构，释放企业潜能，创造无限价值</p>
      <img className="image" src="http://image.wuyougoodcar.com/renhuo/2023-06-29/OTSXwrL9IswNhLX4Ly9j6lt4ETdy8l2q5QEAHqlf.png"></img>
      <img className="image1" src="http://image.wuyougoodcar.com/renhuo/2023-07-02/8x3TMBePN4drW4SYL8kkc8Q14K4xKPtscirkCsKZ.png"></img>
      <img className="image2" src="http://image.wuyougoodcar.com/renhuo/2023-07-02/8x3TMBePN4drW4SYL8kkc8Q14K4xKPtscirkCsKZ.png"></img>
    </div>
  )
}
export default function Home() {
  const platformData = {
    title: '星耀AI数字平台',
    abstract: '星耀AI数字成立于2019年08月，总部位于中国杭州，是一家以服务智能化和知识智能化为核心的人工智能创新企业。',
    content: '星耀AI通过打造开放开源，集体进步，任劳任怨的星耀数字劳动力，为客户提供近乎无限供给的数字员工，大幅度降低组织成本，快速提升组织效率，让客户在数字化转型中拥有以算力为核心的创新竞争力。公司目前拥有多项授权专利，业务覆盖多个行业和近4万家企业，其中包括工商银行、中国银行、招商银行、浦发银行、兴业银行、中信银行、南京银行等众多大型金融机构。公司成立以来，以AI商业落地为核心，赢得众多投资机构的青睐，公司先后获得多轮融资，包括腾讯战投、招银国际、招银电信、国新央企、海松资本、红杉资本、浦信资本、中财奇虎等众多投资机构，也让星耀AI成为⼈⼯智能领域的一匹黑马。星耀AI大力提倡以数字化为根基的星耀文明，致力于将人类从繁重的重复性脑力劳动中解放出来，促进组织的碳达峰和碳中和，让人回归人的价值。',
    list: [
      {
        value: '15+', title: '技术专利', content: '15+技术专利，只为提供更专业的行业解决方案', id: 1
      },
      {
        value: '40000+', title: '覆盖行业', content: '40000+企业合作，让AI商业应用场景更为真实且广泛', id: 2
      },
      {
        value: '100+', title: '团队实力', content: '100+行业内精尖团队成员，以技术赋能更多AI商业应用落地', id: 3
      },
    ]

  }
  const fiveBoxData = {
    title: '核心竞争力',
    abstract: '技术、产品、专利、团队、服务五大矩阵奠定了全球商业化智能交互领域的优势地位',
    list: [
      { id: 1, title: "汇聚全球AI人才，团队规模400+，50%以上为研发人才", url: 'http://image.wuyougoodcar.com/renhuo/2023-06-29/sz4TH7OkaXtS0dgFJWm9xYlZLBvQQLIS2TLAvOlv.png' },
      { id: 2, title: "丰富的服务经验，是工行、招行、建行、中行等大型银行的深度合作伙伴", url: 'http://image.wuyougoodcar.com/renhuo/2023-06-29/zgYUB5imS234E5ufSTiu9niMrrILztYI2HTIRwKW.png' },
      { id: 3, title: "自研AI技术，领跑创新，快速迭代，实力雄厚", url: 'http://image.wuyougoodcar.com/renhuo/2023-06-29/LKAMeCdykswVlu01iwmdSamC0hkpM1k9xNoagHMI.png' },
      { id: 4, title: "四大产品平台，行业定制专属解决方案，覆盖40+细分行业", url: 'http://image.wuyougoodcar.com/renhuo/2023-06-29/s7V2FrDwyKHD2UP60ygn8nXF4GKGyQnxBXIeT5vS.png' },
      { id: 5, title: "坚持自主研发，拥有多项知识产权", url: 'http://image.wuyougoodcar.com/renhuo/2023-06-29/eoD18pYssuvns8sUiVx9BJu3sgMmnvCczuWtc7o0.png' },
    ]
  }
  const windowWidth = window.innerWidth
  return (
    <div className="home-container hidden-scroll">
      <ReactFullpage navigation={false} render={(state) => {
        if (state.fullpageApi) {
          state.fullpageApi.setScrollingSpeed(1)
          state.fullpageApi.moveTo(1)
          state.fullpageApi.setScrollingSpeed(700)
        }
        const nextPage = () => {
          state.fullpageApi.moveTo(2)
        }
        return (
          <ReactFullpage.Wrapper>
            <div className="container-first section">
              <Page1 nextPage={nextPage}></Page1>
            </div>
            <div className="section">
              <Platform platformData={platformData}></Platform>
            </div>
            <div className="section">
              <Page3></Page3>
            </div>
            <div className="section">
              <Page4></Page4>
            </div>
            <div className="section">
              <FiveBox fiveBoxData={fiveBoxData}></FiveBox>
            </div>
            <div className="section">
              <Page5></Page5>
            </div>
            <div className="section">
              <Page6></Page6>
            </div>
            <div className="section">
              <JoinUs></JoinUs>
            </div>
            {
              windowWidth >= 996 ? <div className="section section-footer">
                <Footer></Footer>
              </div> :
                <div className="section">
                  <Footer></Footer>
                </div>
            }

          </ReactFullpage.Wrapper>
        )
      }}>

      </ReactFullpage>

    </div>
  )
}